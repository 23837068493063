import React, { useState } from 'react';
import { Container, Typography, TextField, Slider, Button } from '@mui/material';
import ResponsiveAppBar from '../../components/ResponsiveAppBar/ResponsiveAppBar';
import { generateCode } from '../../api/adminApi';

function Admin() {
  const [tokens, setTokens] = useState(30);
  const [code, setCode] = useState('Code');

  const handleSliderChange = (event, newValue) => {
    setTokens(newValue);
  };

  const handleInputChange = (event) => {
    setTokens(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleBlur = () => {
    if (tokens < 0) {
      setTokens(0);
    } else if (tokens > 1000) {
      setTokens(1000);
    }
  };

  // if a user isnt an admin, then redirect to home
  

  const handleSubmit = async () => {
    // Handle submission logic here
    const response = await generateCode(tokens);
    setCode(response.code);
  };

  return (
    <div>
      <ResponsiveAppBar />
      <Container>
        <Typography variant="h4" gutterBottom>
          Admin Page
        </Typography>
        <TextField
          label="Number of Tokens"
          type="number"
          value={tokens}
          onChange={handleInputChange}
          onBlur={handleBlur}
          inputProps={{
            step: 1,
            min: 0,
            max: 1000,
          }}
        />
        <Slider
          value={typeof tokens === 'number' ? tokens : 0}
          onChange={handleSliderChange}
          aria-labelledby="input-slider"
          min={0}
          max={1000}
        />
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Generate Code
        </Button>
        
        <Typography variant="h6" gutterBottom>
          {code}
        </Typography>
          
      </Container>
    </div>
  );
}

export default Admin;
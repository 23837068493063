import { BASE_URL } from '../constants';

const AUTH_BASE_URL = `${BASE_URL}/auth`;

// TODO: error handling

export const login = async (username, password) => {
  const response = await fetch(`${AUTH_BASE_URL}/login-web`, {
    method: 'POST',
    credentials: 'include', // automatically send cookies for the same origin
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ username, password })
  });

  if (!response.ok) {
    const data = await response.json()
    alert("Error: " + data.error)
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data
};

export const logout = async () => {
  const response = await fetch(`${AUTH_BASE_URL}/logout`, {
    method: 'POST',
    credentials: 'include', // automatically send cookies for the same origin
  });

  if (!response.ok) {
    const data = await response.json()
    alert("Error: " + data.error)
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  console.log('Logged out');
};

export const register = async (email, username, password) => {
  const response = await fetch(`${AUTH_BASE_URL}/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email, username, password })
  });

  if (!response.ok) {
    const data = await response.json()
    alert("Error: " + data.error)
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
}

export const resetPassword = async (email) => {
  const response = await fetch(`${AUTH_BASE_URL}/reset-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email })
  });

  if (!response.ok) {
    const data = await response.json()
    alert("Error: " + data.error)
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  alert("Password reset email sent")
  return data;
}


export const refreshToken = async () => {
  const response = await fetch(`${AUTH_BASE_URL}/refresh-token-web`, {
    method: 'POST',
    credentials: 'include', // Include cookies in the request
  });

  if (!response.ok) {
    const data = await response.json()
    // const data = await response.json()
    // alert("Error: " + data.error)
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

import React, { useState } from 'react';
import { TextField, Button, Typography } from '@mui/material';
import { login }  from '../../api/authApi';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../contexts/UserContext'
import styles from './Login.module.css';
import ResponsiveAppBar from '../../components/ResponsiveAppBar/ResponsiveAppBar';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { setUser } = React.useContext(UserContext);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = await login(username, password);
      setUser(data.user)
      navigate('/'); // Redirect to home page
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.pageContainer}>
      <ResponsiveAppBar />
      <div className={styles.container}>
        <form onSubmit={handleSubmit} className={styles.form}>
        <Typography variant="h4" gutterBottom className={styles.title}>
          Login
        </Typography>
          <TextField
            label="Username"
            value={username}
            onChange={e => setUsername(e.target.value)}
            className={styles.inputField}
          />
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            className={styles.inputField}
          />
          <Button type="submit" variant="contained" color="primary" className={styles.redeemButton}>
            Submit
          </Button>
          <Button className={styles.redirectButton} onClick={() => navigate('/create-account')}>Create Account</Button>
          <Button className={styles.redirectButton} onClick={() => navigate('/forgot-password')}>Forgot Password</Button>
        </form>
      </div>
    </div>
  );
}

export default Login;
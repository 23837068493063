import { BASE_URL } from "../constants";

export const downloadGame = async (type) => {
  const req_body = JSON.stringify({type});
  const response = await fetch(`${BASE_URL}/web/download/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: req_body
  });

  if (!response.ok) {
    const errorData = await response.json();
    const errorMessage = errorData.error;
    window.alert(`An error occurred: ${errorMessage}`);
    throw new Error(errorMessage);
  }

  const data = await response.blob(); // Get the blob of the file
  console.log(data)
  const url = window.URL.createObjectURL(data);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "nuigame.zip");
  document.body.appendChild(link);
  link.click();
  link.remove();

  return data;
}

// Will contain number of downloads in data.downloads
// Number data.downloads
export const getWebStats = async () => {
  const response = await fetch(`${BASE_URL}/web/webstats`, {
    credentials: "include",
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error);
  }

  const data = await response.json();
  return data;
}



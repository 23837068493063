import React, { useState } from 'react';
import { TextField, Button, Typography } from '@mui/material';
import { resetPassword }  from '../../api/authApi';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../contexts/UserContext'
import styles from './ForgotPassword.module.css';
import ResponsiveAppBar from '../../components/ResponsiveAppBar/ResponsiveAppBar';

function ForgotPassword() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { setUser } = React.useContext(UserContext);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = await resetPassword(username);
      setUser(data.user)
      navigate('/'); // Redirect to home page
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.pageContainer}>
      <ResponsiveAppBar />
      <div className={styles.container}>
        <form onSubmit={handleSubmit} className={styles.form}>
        <Typography variant="h4" gutterBottom className={styles.title}>
          Forgot Password
        </Typography>
          <TextField
            label="Email"
            value={username}
            onChange={e => setUsername(e.target.value)}
            className={styles.input}
          />
          <Button type="submit" variant="contained" color="primary" className={styles.button}>
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;
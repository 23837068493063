import { BASE_URL } from '../constants';

const UPDATED_BASE_URL = BASE_URL + '/api/userdata';

export const getUsername = async () => {
  const response = await fetch(`${UPDATED_BASE_URL}/username`, {
    credentials: 'include'
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error);
  }

  const data = await response.json();
  return data;
};

export const getUserData = async () => {
  const response = await fetch(`${UPDATED_BASE_URL}/userdata`, {
    credentials: 'include'
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error);
  }

  const data = await response.json();
  return data;
};

export const getUserDataByUsername = async (username) => {
  const response = await fetch(`${UPDATED_BASE_URL}/userdata/${username}`, {
    credentials: 'include'
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error);
  }

  const data = await response.json();
  return data;
}

export const getUserConfig = async () => {
  const response = await fetch(`${UPDATED_BASE_URL}/userconfig`, {
    credentials: 'include'
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error);
  }

  const data = await response.json();
  return data;
};

export const getRunStats = async () => {
  const response = await fetch(`${UPDATED_BASE_URL}/runstats`, {
    credentials: 'include'
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error);
  }

  const data = await response.json();
  return data;
};

export const getGlobalChatMessages = async () => {
  const response = await fetch(`${UPDATED_BASE_URL}/globalchatmessages`, {
    credentials: 'include'
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error);
  }

  const data = await response.json();
  return data;
};

export const redeemCode = async (code) => {
  console.log(`Redeeming code: ${code}`)
  const response = await fetch(`${UPDATED_BASE_URL}/redeemcode`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ code })
  });

  if (!response.ok) {
    const errorData = await response.json();
    return errorData;
  }

  const data = await response.json();
  return data;
}

export const changeProfilePicture = async (picture) => {
  const formData = new FormData();
  formData.append('picture', picture);
  console.log(`Changing profile picture to: ${picture}`)
  const response = await fetch(`${UPDATED_BASE_URL}/profilepicture`, {
    method: "POST",
    body: formData, // Send the picture as form data
    credentials: 'include',
  });

  if (!response.ok) {
    const errorData = await response.json();
    alert(errorData.error);
    throw new Error(errorData.error);
  }

  const data = await response.json();
  return data;
}
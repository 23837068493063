import React, { useState } from 'react';
import styles from './CreateAccount.module.css';
import { useNavigate } from "react-router-dom";
import { TextField, Button, Typography } from '@mui/material';
import ResponsiveAppBar from '../../components/ResponsiveAppBar/ResponsiveAppBar';
import { register } from '../../api/authApi';
import UserContext from '../../contexts/UserContext'

function CreateAccount() {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { setUser } = React.useContext(UserContext);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = await register(email, username, password);
      setUser(data.user);
      navigate('/'); // Redirect to home page
    }
    catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.pageContainer}>
      <ResponsiveAppBar />
      <div className={styles.container}>
        <form onSubmit={handleSubmit} className={styles.form}>
        <Typography variant="h4" gutterBottom className={styles.title}>
          Create Account
        </Typography>
          <TextField
            label="Username"
            value={username}
            onChange={e => setUsername(e.target.value)}
            className={styles.input}
          />
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            className={styles.input}
          />
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            className={styles.input}
          />
          <Button type="submit" variant="contained" color="primary" className={styles.button}>
            Submit
          </Button>
          <Button className={styles.redirectButton} onClick={() => navigate('/login')}>Login with exisiting account</Button>
        </form>
      </div>
    </div>
  );
}

export default CreateAccount;
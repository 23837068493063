import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Button, TextField, Box, Avatar, Container } from '@mui/material';
import styles from './Profile.module.css';
import ResponsiveAppBar from '../../components/ResponsiveAppBar/ResponsiveAppBar';
import { getUserDataByUsername } from '../../api/userdataApi';
import image from './Nui_Shiho.png'; // adjust the path as needed

function Profile() {
  const [userdata, setUserData] = useState({
    username: '',
    level: '',
    coinCount: '',
    tokenCount: '',
    profilePicture: image
  
  });

  const { username: paramUsername } = useParams();

  const username = paramUsername || "shiho"
  
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const res = await getUserDataByUsername(username);
        setUserData(res);
      } catch (err) {
      }
    };

    fetchUserData();
  }, [username]);

  return (
    <div className={styles.pageContainer}>
      <ResponsiveAppBar />
      <div className={styles.container}>
        <div className={styles.form}>
          <div className={styles.avatarContainer}>
            <Avatar src={userdata.profilePicture}
              alt={userdata.username}
              className={styles.editavatar}
            />
          </div>
          <div className={styles.infoContainer}>
            <Box>
              <strong>Name:</strong> {userdata.username}
            </Box>
            <Box>
              <strong>Level:</strong> {userdata.level}
            </Box>
            <Box>
              <strong>Coins:</strong> {userdata.coinCount}
            </Box>
            <Box>
              <strong>Tokens:</strong> {userdata.tokenCount}
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
import React from 'react';
import styles from './About.module.css';
import ResponsiveAppBar from '../../components/ResponsiveAppBar/ResponsiveAppBar.js';

function About() {
  return (
    <div className={styles.pageContainer}>
      <ResponsiveAppBar/>
    <div className={styles.container}>
        <div className={styles.form}>
          <h1>About The Nui World</h1>

          <div className={styles.row}>
            <section className={styles.section}>
              <h2>Gameplay</h2>
              <p>Top down shooting game where you can play as cute nuis.</p>
            </section>

            <section className={styles.section}>
              <h2>Contact Us</h2>
              <p>For any inquiries, please email us at: nuigamedev@gmail.com</p>
            </section>
          </div>

          <div className={styles.row}>
            <section className={styles.section}>
              <h2>About the Game</h2>
              <p>The Nui World is filled with mystical Nui's such as Shiho and Ichika.</p>
              <p>Take part in various battle against enemies such as Bears, Birds, and finally THE BIG BOSS.</p>
            </section>

            <section className={styles.section}>
              <h2>Credits</h2>
              <p><u>Developers</u>: Jeriko, Chloe, Shrey, Henry, Karen</p>
              <p><u>Special Thanks</u>: Shi Yu, Jakob Hain, Shiho</p>
            </section>

            <section className={styles.section}>
              <h2>History</h2>
              <p>This project started out as a game, but evolved into a full stack project including a ReactJS website, messaging system, AI chat, NodeJS server, and MongoDB database</p>
              <p>This webdev stack is hosted with AWS</p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
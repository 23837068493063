import React from 'react';
import ResponsiveAppBar from '../../components/ResponsiveAppBar/ResponsiveAppBar.js';
import styles from './Home.module.css';
import gameImage from './Nui_Shiho.png'; // replace with the path to your image
import backgroundImage from './New-Game-UI.png'; // replace with the path to your background image

function Home() {
  return (
    <div>
      <ResponsiveAppBar />
      <div className={styles.background} style={{ backgroundImage: `url(${backgroundImage})` }}></div>
      <div className={styles.container}>
        <div className={styles.box}>
          <section className={styles.description}>
            <h1 className={styles.title}>From the Nui World</h1>
            <p>Welcome to the Nui World, a world filled with mystical Nui's such as Shiho and Ichika.</p>
            <p> Take part in various battle against enemies such as Bears, Birds, and finally THE BIG BOSS.</p>
          </section>
        </div>
        <div className={styles.imageContainer}>
          <img src={gameImage} alt="Game" className={styles.gameImage} />
        </div>
        <div className={styles.box}>
          <p1> A world of adventure awaits. Download Now!</p1>
        </div>
        {/* <section className={styles.download}>
          <button className={styles.downloadButton}>Download</button>
        </section> */}
      </div>
    </div>
  );
}

export default Home;
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { changeProfilePicture, getUserData } from '../../api/userdataApi'; 
import { Button, TextField, Box, Avatar, Container } from '@mui/material';
import ResponsiveAppBar from '../../components/ResponsiveAppBar/ResponsiveAppBar';
import styles from './EditProfile.module.css'
import UserContext from '../../contexts/UserContext';

function EditProfile() {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = React.createRef();

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      const fileURL = URL.createObjectURL(file);
      setUser({ ...user, profilePicture: fileURL });
    }
  };

  const handleImageUpload = async () => {
    if (selectedFile) {
      try {
      await changeProfilePicture(selectedFile);
      alert("Profile picture updated successfully")
      } catch (error) {
      // TODO: error handling
      console.error(error);
      }
    }
  };




  if (!user) {
    return null;
  }


  return (
    <div className={styles.pageContainer}>
      <ResponsiveAppBar />
      <div className={styles.container}>
        <div className={styles.form}>
          <div className={styles.avatarContainer}>
          <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <Avatar src={user.profilePicture}
              alt={user.username}
              className={styles.editavatar}
              onClick={handleAvatarClick}
            />
            <Button type="button" variant="contained" onClick={handleImageUpload}>Update Profile Picture</Button>
          </div>
          <div className={styles.infoContainer}>
            <Box>
              <strong>Name:</strong> {user.username}
            </Box>
            <Box>
              <strong>Level:</strong> {user.level}
            </Box>
            <Box>
              <strong>Coins:</strong> {user.coinCount}
            </Box>
            <Box>
              <strong>Tokens:</strong> {user.tokenCount}
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditProfile;
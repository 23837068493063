import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from 'react';
import { muiTheme } from './constants.js';
import { createTheme, keyframes, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import UserContext from './contexts/UserContext';
import { refreshToken } from './api/authApi.js'
import { create } from '@mui/material/styles/createTransitions.js';

// IMPORT COMPONENTS
import Login from './pages/Login/Login.js';
import CreateAccount from './pages/CreateAccount/CreateAccount.js';
import Home from './pages/Home/Home.js';
import Profile from './pages/Profile/Profile.js';
import Admin from './pages/Admin/Admin.js';
import Redeem from './pages/Redeem/Redeem.js';
import Download from './pages/Download/Download.js';
import About from './pages/About/About.js';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword.js';
import EditProfile from './pages/EditProfile/EditProfile.js';

function App() {
  const [user, setUser] = useState(null);

  const theme = createTheme(muiTheme);

  useEffect(() => {
    refreshToken() 
      .then((res) => {
        console.log(res)
        setUser(res.user);
      })
      .catch((err) => {
        console.log(err);
      })
  }, [])

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <div className="App">
        <ThemeProvider theme={theme}>
          <Router>

            <Routes>
              {/* Add routes here */}\
              <Route exact path="/" element={<Home />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/create-account" element={<CreateAccount />} />
              <Route exact path="/profile" element={<Navigate to={`/profile/${user?.username}`} />} />
              <Route exact path="/profile/:username" element={<Profile />} />
              <Route exact path="/editprofile" element={<EditProfile />} />
              <Route exact path="/redeem" element={<Redeem />} />
              <Route exact path="/admin" element={<Admin />} />
              <Route exact path="/download" element={<Download />} />
              <Route exact path="/about" element={<About />} />
              <Route exact path="/forgot-password" element={<ForgotPassword />} />
            {/* If no route matches, redirect to home */}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Router>
        </ThemeProvider>
      </div>
    </UserContext.Provider>
  );
}

export default App;
export const BASE_URL = 'https://api.nuigame.net';

export const colors = {
  color1: '#c3b7e5',
  color2: '#9f9ed7',
  color3: '#52508d',
  dark: '#080309',
  highlight: '#DBFF76'
};

export const muiTheme = {
  palette: {
    primary: {
      main: colors.color2
    },
    secondary: {
      main: colors.color1
    },
    text: {
      primary: colors.dark,
      secondary: colors.color3,
    }
  }
};

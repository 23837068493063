import React, { useState } from 'react';
import { Container, Typography, TextField, Button } from '@mui/material';
import ResponsiveAppBar from '../../components/ResponsiveAppBar/ResponsiveAppBar';
import { redeemCode } from '../../api/userdataApi';
import styles from './Redeem.module.css'; 

function Redeem() {
  const [code, setCode] = useState([]);
  const [message, setMessage] = useState('');

  const handleTextFieldChange = (event) => {
    if (event.target.value.length <= 8) {
      setCode(event.target.value);
    }
  }

  const handleSubmit = async () => {
    if (code.length !== 8) {
      setMessage('Invalid code');
      return;
    }
    const res = await redeemCode(code);
    if (res.error) {
      setMessage(res.error);
      return;
    } else if (res.message) {
      setMessage(res.message);
      return;
    }
    setMessage('An Error has Occured!');
  };

  return (
    <div className={styles.pageContainer}>
      <ResponsiveAppBar />
      <div className={styles.container}>
        <div className={styles.form}>
          <div className={styles.title}>
            <Typography variant="h4" gutterBottom className={styles.title}>
              Redeem Code
            </Typography>
          </div>
          <div className={styles.inputField}>
            <TextField
              label="Code"
              type="text"
              value={code}
              onChange={handleTextFieldChange}
              className={styles.inputField}
            /> 
          </div>
          <Button variant="contained" color="primary" onClick={handleSubmit} className={styles.redeemButton}>
              Redeem Code
          </Button>
          <Typography variant="h6" gutterBottom className={styles.message}>
            {message}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default Redeem;
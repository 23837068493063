import React, { useState, useEffect } from 'react';
import styles from './Download.module.css';
import ResponsiveAppBar from '../../components/ResponsiveAppBar/ResponsiveAppBar.js';
import { Button } from '@mui/material';
import { downloadGame, getWebStats } from '../../api/api.js';
import gameImage from './Nui_Ichika.png';

function Download() {
  const [totalDownloads, setTotalDownloads] = useState(0);

  const handleDownload = async (version) => {
    console.log(`Download started for ${version} version.`);
    await downloadGame(version);
    await getWebsiteStatistics();
  };

  const getWebsiteStatistics = async () => {
    const response = await getWebStats();
    setTotalDownloads(response.downloads);
    return response;
  }

  useEffect(() => {
    async function fetchData() {
      const response = await getWebStats();
      setTotalDownloads(response.downloads);
    }

    fetchData();
  }, []);

  return (
    <div className={styles.pageContainer}>
      <ResponsiveAppBar />
      <div className={styles.container}>
        <div className={styles.form}>
          <div className={styles.titleContainer}>
            <h1>Download The Nui World</h1>
            <p>Total Downloads: {totalDownloads}</p>
          </div>
          <div className={styles.downloadsContainer}>
            <div className={styles.macContainer}>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={() => handleDownload('Mac')} 
                className={styles.button}
              >
                Mac Version
              </Button>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={() => handleDownload('mac_intel')} 
                className={styles.button}
              >
                Mac Version (Intel)
              </Button>
            </div>
            <div className={styles.imageContainer}>
              <img src={gameImage} alt="Game" className={styles.gameImage} />
            </div>
            <div className={styles.windowsContainer}>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={() => handleDownload('Windows')} 
                className={styles.button}
              >
                Windows Version
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Download;
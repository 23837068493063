import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import styles from './ResponsiveAppBar.module.css';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { logout } from '../../api/authApi';
import UserContext from '../../contexts/UserContext';
import { muiTheme } from '../../constants';

const pages = ['Game', 'Download', 'About'];
const settings = ['Profile', 'Redeem Code', 'Edit Profile', 'Logout'];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLoginClick = () => {
    navigate('/login');
  };

  const handleLogoutClick = () => {
    logout();
    navigate('/');
    handleCloseUserMenu();
    setUser(null);
    // navigate('/login');
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  const settingHandlers = {
    'Profile': () => navigate('/profile'),
    'Edit Profile': () => navigate('/editprofile'),
    'Redeem Code': () => navigate('/redeem'),
    'Account': () => navigate('/account'),
    'Dashboard': () => navigate('/dashboard'),
    'Logout': handleLogoutClick,
  }

  const pageHandlers = { 
    'Game': () => navigate('/'),
    'Download': () => navigate('/download'),
    'About': () => navigate('/about'),
  }
  
  return (
    <AppBar position="static" className={styles.navbar}>
      <Container maxWidth="xl" className={styles.container}>
        <Toolbar disableGutters className={styles.toolbar}>
          {/* <AdbIcon className={styles.adbIcon} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            className={`${styles.logoTypography} ${styles.logoTypographyMd}`}
          >
            NuiGame
          </Typography> */}
          <IconButton onClick={handleLogoClick} className={styles.menuButton}>
            <img src={process.env.PUBLIC_URL + '/shiho48.gif'} alt="logo" className={styles.logo} />
          </IconButton>

          <Box className={styles.box}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={pageHandlers[page]}
              >
                <Typography color="text.primary">
                  {page}
                </Typography>
              </Button>
            ))}
          </Box>

          <Box className={styles.boxRight}>
            <Tooltip title="Open settings">
              {user ? (
                <Avatar src={user.profilePicture} onClick={handleOpenUserMenu} />
              ) : (
                <Button color="inherit" onClick={handleLoginClick}>
                  <Typography color="text.primary">
                    Login
                  </Typography>
                </Button>
              )}
            </Tooltip>
            <Menu
              className={styles.userMenu}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={settingHandlers[setting]}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
import { BASE_URL } from "../constants";

const ADMIN_BASE_URL = BASE_URL + '/admin';

export const generateCode = async (tokens) => {
  const response = await fetch(`${ADMIN_BASE_URL}/generatecode`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ tokens }),
    credentials: 'include'
  });

  if (!response.ok) {
    const errorData = await response.json();
    alert("Error: " + errorData.error)
    throw new Error(errorData.error);
  }

  const data = await response.json();
  return data;
}

export const getCodes = async () => {
  const response = await fetch(`${ADMIN_BASE_URL}/codes`, {
    credentials: 'include'
  });

  if (!response.ok) {
    const errorData = await response.json();
    alert("Error: " + errorData.error)
    throw new Error(errorData.error);
  }

  const data = await response.json();
  return data;
}

export const isAdmin = async () => {
  const response = await fetch(`${ADMIN_BASE_URL}/is-admin`, {
    credentials: 'include'
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error);
  }

  const data = await response.json();
  return data;
}